import { Profile } from '@/types/Profile';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthStoreInterface {
  authenticated: boolean;
  setAuthentication: (val: boolean) => void;
  user: Profile | null;
  setUser: (user: Profile | null) => void;
}

export const useAuthStore = create(
  persist<AuthStoreInterface>(
    (set) => ({
      authenticated: false,
      user: null,
      setAuthentication: (val: boolean) =>
        set((state) => ({ authenticated: val })),
      setUser: (user: Profile | null) => set({ user }),
    }),
    {
      name: 'user',
    }
  )
);
/* export const useAuthStore = create<AuthStoreInterface>((set) => ({
  authenticated: false,
  user: null,
  setAuthentication: (val) => set((state) => ({ authenticated: val })),
  setUser: (user) => set({ user }),
}));
 */
