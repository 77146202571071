import Link from 'next/link';

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  variant?: InputVariant;
}

export enum InputVariant {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  White = 'WHITE',
  Ghost = 'GHOST',
}

export default function Input({ variant, ...rest }: InputProps) {
  let classes = 'bg-white';

  if (variant === InputVariant.Secondary) {
    classes = 'bg-black hover:bg-gray-700 text-white';
  }

  if (variant === InputVariant.White) {
    classes = 'bg-white hover:bg-gray-100 text-black';
  }

  if (variant === InputVariant.Ghost) {
    classes = 'bg-transparent text-black';
  }

  return (
    <input
      {...rest}
      className={`block h-14 items-center rounded-full border px-4 text-base font-semibold outline-black transition duration-200 ease-in-out lg:px-8 ${classes} ${rest.className}`}
    />
  );
}
